import React from "react"
import MobileHeader from "./mobileHeader"
import DesktopHeader from "./desktopHeader"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "gatsby-source-prismic-graphql"

const headerQuery = graphql`
  query HeaderQuery {
    prismic {
      allHomepages {
        edges {
          node {
            haully_logo
            pilot_transport_logo
            united_road_header_logo
          }
        }
      }
      allVehicle_movess {
        edges {
          node {
            _linkType
            _meta {
              uid
            }
            body {
              ... on PRISMIC_Vehicle_movesBodyService_info {
                primary {
                  service_title
                }
              }
            }
          }
        }
      }
    }
  }
`

const HeaderRenderer = ({ data, activePage, bottomBorder }) => {
  const headerData = data.prismic && data.prismic.allHomepages.edges[0]
  if (!headerData) return null

  const vehicleMovePagesData =
    data.prismic && data.prismic.allVehicle_movess.edges
  if (!vehicleMovePagesData) return null

  const remarketedVehicleSectionTitles = getSectionTitles(
    "remarketed-vehicle-moves",
    vehicleMovePagesData,
  )
  const newVehicleSectionTitles = getSectionTitles(
    "new-vehicle-moves",
    vehicleMovePagesData,
  )
  const {
    united_road_header_logo,
    haully_logo,
    pilot_transport_logo,
  } = headerData.node

  return (
    <div>
      <DesktopHeader
        bottomBorder={bottomBorder}
        activePage={activePage}
        remarketedVehicleSectionTitles={remarketedVehicleSectionTitles}
        newVehicleSectionTitles={newVehicleSectionTitles}
        unitedRoadHeaderLogo={united_road_header_logo}
        haullyLogo={haully_logo}
        pilotTransportLogo={pilot_transport_logo}
      />
      <MobileHeader
        unitedRoadLogo={united_road_header_logo}
        remarketedVehicleSectionTitles={remarketedVehicleSectionTitles}
        newVehicleSectionTitles={newVehicleSectionTitles}
      />
    </div>
  )
}

function getSectionTitles(pageTitle, pageData) {
  const vehiclePageData = pageData.find(
    (page) => page.node._meta.uid === pageTitle,
  )
  const vehicleSections = vehiclePageData.node.body.filter(
    (section) => section.__typename === "PRISMIC_Vehicle_movesBodyService_info",
  )
  return (
    vehicleSections.map((section) => section.primary.service_title[0]?.text) ||
    []
  )
}

export default function Header({ activePage, bottomBorder }) {
  return (
    <StaticQuery
      query={`${headerQuery}`}
      render={withPreview(
        (data) => (
          <HeaderRenderer
            data={data}
            activePage={activePage}
            bottomBorder={bottomBorder}
          />
        ),
        headerQuery,
      )}
    />
  )
}
