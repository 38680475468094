export const homePath = () => "/"
export const aboutUsPath = () => "/about"
export const careersPath = () => "/careers"
export const contactUsPath = () => "/contact"
export const todoPath = () => "/todo"
export const employeeResourcesPath = () => "/employee-resources"
export const remarketedVehicleMovesPath = () => "/remarketed-vehicle-moves"
export const individualVehicleMovesPath = () => "/individual-vehicle-moves"
export const specialtyVehicleMovesPath = () => "/specialty-vehicle-moves"
export const newVehicleMovesPath = () => "/new-vehicle-moves"
export const termsAndConditionsPath = () => "/terms-conditions"
export const termsOfUse = () => "/terms-of-use"
export const privacyPolicyPath = () => "/privacy-policy"
export const leadershipTeamPath = () => "/leadership-team"
export const mediaCenterPath = () => "/media-center"
export const siteMapPath = () => "/sitemap.xml"
export const givingBackPath = () => "/giving-back"
export const sustainabilityPath = () => "/sustainability"

// EXTERNAL LINKS
export const trucksForSaleURL = () => "https://forsale.unitedroad.com"
export const facebookURL = () => "https://www.facebook.com/unitedroadservices/"
export const twitterURL = () => "https://twitter.com/unitedroadfvl?lang=en"
export const linkedInURL = () => "https://www.linkedin.com/company/united-road/"
export const youTubeURL = () =>
  "https://www.youtube.com/channel/UCNM3puLdMduxcQpe-U0b-HA"
export const haullyURL = () => "https://www.haully.com/"
export const pilotTransportURL = () => "http://www.pilottransport.com/"
export const loginURL = () =>
  "https://unitedroad.vtvsolutions.com/Oviss/Login.aspx?ReturnUrl=%2foviss%2fdefault.aspx"
export const onlineStoreURL = () =>
  "https://united-road-services-awardco.axomo.com/category/united-road-services-swag-store/"
export const citrixLoginURL = () => "https://remote.unitedroad.com"
export const customerPortalUrl = () => "https://moves.unitedroad.com"
